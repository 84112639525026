import { UtilitiesService } from './utilities.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class RequestService {

    private accessToken: any = '';
    private headersTypes: any = {
        file: new HttpHeaders({
            'Accept': 'application/json'
        }),
        json: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    };

    constructor(private http: HttpClient, private utilities: UtilitiesService, private router: Router) {

        this.utilities.subscribeEvent("update-access-token", () => {
            this.setAccessToken();
        });

        this.setAccessToken();

    }

    private setAccessToken() {
        this.accessToken = this.utilities.getData('accessToken', '');
        if (this.accessToken) {

            this.headersTypes.file = new HttpHeaders({
                'Authorization': 'Bearer ' + this.accessToken,
                'Accept': 'application/json'
            });
            this.headersTypes.json = new HttpHeaders({
                'Authorization': 'Bearer ' + this.accessToken,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            });

        } else {
            this.headersTypes.file = new HttpHeaders({
                'Accept': 'application/json'
            });
            this.headersTypes.json = new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            });
        }
    }

    private setParameters(params: any = {}) {
        return "?" + (new URLSearchParams(params).toString());
    }

    private getHeaderTypeKey(data: any) {

        let keys = Object.keys(data);
        for (let k of keys) {
            if (typeof data[k] == 'object' && (data[k] instanceof File || data[k] instanceof Blob)) {
                return 'file';
            }
        }

        return 'json';
    }

    jsonToFormData(obj: any, form: any = null, namespace: any = null) {

        var fd = form || new FormData();
        var formKey;

        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {

                if (namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }

                // if the property is an object, but not a File,
                // use recursivity.
                if (typeof obj[property] === 'object' && !(obj[property] instanceof File || obj[property] instanceof Blob)) {

                    this.jsonToFormData(obj[property], fd, formKey);

                } else {

                    // if it's a string or a File object
                    if (obj[property] instanceof File || obj[property] instanceof Blob) {
                        fd.append(formKey, obj[property], obj[property].name);
                    } else {
                        fd.append(formKey, typeof obj[property] == 'boolean' ? (obj[property] ? 1 : 0) : obj[property]);

                    }
                }

            }
        }

        return fd;

    }

    private setBodyData(data: any = {}, headerType: string = 'json') {
        if (headerType == 'json') {
            return data;
        } else {
            return this.jsonToFormData(data);
        }
    }


    get(url: string, params: any = null): Observable<any> {
        if (params) {
            params = this.setParameters(params);
        } else {
            params = "";
        }

        return this.http.get(environment.ApiUrl + url + params, {
            headers: this.headersTypes.json
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    delete(url: string, params: any = null): Observable<any> {
        if (params) {
            params = this.setParameters(params);
        } else {
            params = "";
        }

        return this.http.delete(environment.ApiUrl + url + params, {
            headers: this.headersTypes.json
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    put(url: string, data: any = {}): Observable<any> {

        return this.http.put(environment.ApiUrl + url, data, {
            headers: this.headersTypes.json
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }

    post(url: string, data: any = {}): Observable<any> {
        let key: any = this.getHeaderTypeKey(data);
        data = this.setBodyData(data, key)
        return this.http.post(environment.ApiUrl + url, data, {
            headers: this.headersTypes[key]
        }).pipe(
            tap(
                success => success,
                error => this.errorHandler(error)
            ));
    }


    private logout() {

        this.utilities.clearData();
        this.utilities.setData('lang', 'ar');

        this.router.navigateByUrl('/login');

        return this.http.post(environment.ApiUrl + 'logout', {}, {
            headers: new HttpHeaders({
                'Authorization': ('Bearer ' + this.accessToken),
                'Content-Type': 'application/json'
            })
        }).pipe(
            tap(
                (success) => { this.setAccessToken(); return success; },
                (error) => { this.setAccessToken(); return error; }
            ));

    }

    errorHandler(error: any) {
        if (error.status == 401) {
            this.logout();
        }
    }

}
