<div class="logo px-3 text-center">
  <a routerLink="/dashboard/changes">
    <img src="/assets/img/970techmap-logo.svg" style="max-width: 75%;" />
  </a>
</div>


<div class="sidebar-wrapper">

  <div class="user">
    <div class="photo">
      <img src="./assets/img/default-avatar.png" />
    </div>
    <div class="user-info">
      <a data-toggle="collapse" href="#collapseExample" class="collapsed">
        <span>
          {{'user.first_name' | sharedData}} {{'user.last_name' | sharedData}}
          <b class="caret"></b>
        </span>
      </a>
      <div class="collapse" id="collapseExample">
        <ul class="nav">
          <li class="nav-item">
            <a [routerLink]="'/dashboard/users/' + ('user.id' | sharedData)" class="nav-link">
              <span class="sidebar-mini"><i class="material-icons">user</i></span>
              <span class="sidebar-normal">My Profile</span>
            </a>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" (click)="logout()" class="nav-link">
              <span class="sidebar-mini"><i class="material-icons">logout</i></span>
              <span class="sidebar-normal">Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'" class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}</p>
      </a>
      <!--If it have a submenu-->
      <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'" (click)="updatePS()"
        class="nav-link">
        <i class="material-icons">{{menuitem.icontype}}</i>
        <p>{{menuitem.title}}<b class="caret"></b></p>
      </a>

      <!--Display the submenu items-->
      <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item">
            <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
              <span class="sidebar-mini">{{childitem.ab}}</span>
              <span class="sidebar-normal">{{childitem.title}}</span>
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>

</div>