<div class="main-bg"></div>
<nav class="navbar navbar-expand-lg bg-primary navbar-transparent {{('inCity' | sharedData) ? 'add-overlay': ''}}"
  color-on-scroll="500">
  <div class="container">
    <!-- <div class="navbar-wrapper" style="margin-top: 100px;">
      <a class="navbar-brand d-lg-none" [routerLink]="['/']">
        <img src="/assets/img/970techmap-logo.svg">
      </a>
    </div> -->
    <div class="navbar-wrapper" style="margin-top: 100px;">
      &nbsp;
    </div>
    <!-- px-3 text-center d-none d-lg-block -->
    <div class="logo logo-home">
      <a routerLink="/dashboard/changes">
        <!--  style="max-width: 75%;" -->
        <img src="/assets/img/970techmap-logo.svg" />
      </a>
    </div>
    <button mat-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" style="display: none;">
      <ul class="navbar-nav">
        <li class="nav-item" routerLinkActive="active" *ngIf="!('user.id' | sharedData)">
          <a class="nav-link" [routerLink]="['/login']">
            <i class="material-icons">login</i> Login
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="!('user.id' | sharedData)">
          <a class="nav-link" [routerLink]="['/register']">
            <i class="material-icons">person_add</i> Register
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="('user.id' | sharedData)">
          <a class="nav-link" [routerLink]="['/profile']">
            <i class="material-icons">account_circle</i> My Profile
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngIf="('user.id' | sharedData)">
          <a class="nav-link" href="javascript:void(0)" (click)="logout()">
            <i class="material-icons">logout</i> Logout
          </a>
        </li>

        <!-- <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/pages/login']">
            <i class="material-icons">fingerprint</i> Login
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/pages/lock']">
            <i class="material-icons">lock_open</i> Lock
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>