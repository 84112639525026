
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

    private timeZone = '';

    constructor(public translate: TranslateService) {

        moment.locale("en");
        // moment.locale(this.translate.getDefaultLang());
        this.timeZone = moment.tz.guess();
    }

    dayOnly() {
        let self = this;
        return {
            sameDay: '[' + self.translate.instant('TODAY') + ']',
            lastDay: '[' + self.translate.instant('YESTERDAY') + ']',
            nextDay: 'DD-MM-YYYY',
            nextWeek: 'DD-MM-YYYY',
            lastWeek: 'DD-MM-YYYY',
            sameElse: 'DD-MM-YYYY'

        };
    }

    anyTime(key) {
        let self = this;
        let newLine = key == 'any-line' ? ' ' : '[<br>]';
        return {
            sameDay: function (now) {
                if (this.isBefore(now)) {

                    let h = this.diff(now, 'hours') * -1;
                    let m = this.diff(now, 'minutes') * -1;
                    if (h > 0) {
                        if (h == 1) {
                            return '[' + self.translate.instant('1_HOUR_AGO') + ']';
                        } else if (h == 2) {
                            return '[' + self.translate.instant('2_HOURS_AGO') + ']';

                        } else if (h > 10) {
                            return h + ' [' + self.translate.instant('HOUR_AGO') + ']';
                        } else {
                            return h + ' [' + self.translate.instant('HOURS_AGO') + ']';
                        }
                    } else {

                        if (m == 1) {
                            return '[' + self.translate.instant('1_MINUTE_AGO') + ']';
                        } else if (m == 2) {
                            return '[' + self.translate.instant('2_MINUTES_AGO') + ']';

                        } else if (m > 10) {
                            return m + ' [' + self.translate.instant('MINUTE_AGO') + ']';
                        } else {
                            return m + ' [' + self.translate.instant('MINUTES_AGO') + ']';
                        }
                    }
                } else {
                    return '[' + self.translate.instant('TODAY_AT') + '] h:mma';
                }
            },
            lastDay: '[' + self.translate.instant('YESTERDAY_AT') + '] h:mma',
            nextDay: 'DD-MM-YYYY ' + newLine + ' h:mma',
            nextWeek: 'DD-MM-YYYY ' + newLine + ' h:mma',
            lastWeek: 'DD-MM-YYYY ' + newLine + ' h:mma',
            sameElse: 'DD-MM-YYYY ' + newLine + ' h:mma'

        };
    }

    timeOnly() {
        let self = this;
        return {
            sameDay: function (now) {
                if (this.isBefore(now)) {

                    let h = this.diff(now, 'hours') * -1;
                    let m = this.diff(now, 'minutes') * -1;
                    if (h > 0) {
                        if (h == 1) {
                            return '[' + self.translate.instant('1_HOUR_AGO') + ']';
                        } else if (h == 2) {
                            return '[' + self.translate.instant('2_HOURS_AGO') + ']';

                        } else if (h > 10) {
                            return h + ' [' + self.translate.instant('HOUR_AGO') + ']';
                        } else {
                            return h + ' [' + self.translate.instant('HOURS_AGO') + ']';
                        }
                    } else {

                        if (m == 1) {
                            return '[' + self.translate.instant('1_MINUTE_AGO') + ']';
                        } else if (m == 2) {
                            return '[' + self.translate.instant('2_MINUTES_AGO') + ']';

                        } else if (m > 10) {
                            return m + ' [' + self.translate.instant('MINUTE_AGO') + ']';
                        } else {
                            return m + ' [' + self.translate.instant('MINUTES_AGO') + ']';
                        }
                    }
                } else {
                    return '';
                }
            },
            lastWeek: 'hh:mma',
            lastDay: 'hh:mma',
            sameElse: 'hh:mma',
            nextDay: 'hh:mma',
            nextWeek: 'hh:mma',
        };
    }

    time() {
        let self = this;
        return {
            sameDay: 'hh:mma',
            lastWeek: 'hh:mma',
            lastDay: 'hh:mma',
            sameElse: 'hh:mma',
            nextDay: 'hh:mma',
            nextWeek: 'hh:mma',
        };
    }

    dateTime() {
        let self = this;
        return {
            sameDay: 'DD-MM-YYYY hh:mma',
            lastWeek: 'DD-MM-YYYY hh:mma',
            lastDay: 'DD-MM-YYYY hh:mma',
            sameElse: 'DD-MM-YYYY hh:mma',
            nextDay: 'DD-MM-YYYY hh:mma',
            nextWeek: 'DD-MM-YYYY hh:mma',
        };
    }


    dateOnly() {
        let self = this;
        return {
            sameDay: 'DD-MM-YYYY',
            lastDay: 'DD-MM-YYYY',
            nextDay: 'DD-MM-YYYY',
            nextWeek: 'DD-MM-YYYY',
            lastWeek: 'DD-MM-YYYY',
            sameElse: 'DD-MM-YYYY'

        };
    }

    transform(value: any, key: any, key2: any = false): any {
        let self = this;

        let config: any = {};
        let isFormat = false;

        if (key == 'any' || key == 'any-line') {
            config = this.anyTime(key);
        } else if (key == 'day') {
            config = self.dayOnly();
        } else if (key == 'time') {
            config = self.time();
        } else if (key == 'dateTime') {
            config = self.dateTime();
        } else if (key == 'date') {
            config = self.dateOnly();
        } else if (key == 'format') {
            isFormat = true;
            config = {
                sameDay: key2,
                lastDay: key2,
                nextDay: key2,
                nextWeek: key2,
                lastWeek: key2,
                sameElse: key2
            };

        } else if (key == 'toAMPM') {
            return moment.tz(moment(value, 'HH:mm'), 'UTC').tz(this.timeZone).format('hh:mm A');
        } else {
            config = self.timeOnly();
        }

        let time = "";

        if (value) {
            time = moment.tz(value, 'UTC').tz(this.timeZone).calendar(null, config);

            if (key2 && isFormat) {
                time = moment.tz(value, 'UTC').tz(this.timeZone).calendar(null, config);
            }
        }
        return time;
    }

}
