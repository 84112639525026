export const environment = {
  production: true,
  ApiUrl: '/api/',
  StorageUrl: '/storage/',
  cities: [
    { id: 15, name: 'Jerusalem' },
    { id: 14, name: 'Ramallah and Al-Bireh' },
    { id: 10, name: 'Nablus' },
    { id: 17, name: 'Bethlehem' },
    { id: 18, name: 'Hebron' },
    { id: 9, name: 'Tubas' },
    { id: 12, name: 'Jericho' },
    { id: 13, name: 'Sulfites' },
    { id: 20, name: 'Gaza' },
    { id: 19, name: 'North Gaza' },
    { id: 21, name: 'Dair Al Balah' },
    { id: 22, name: 'Khan Younes' },
    { id: 23, name: 'Rafah' },
    { id: 4, name: 'Jaffa' },
    { id: 7, name: 'Jenin' },
    { id: 8, name: 'Tulkarm' },
    { id: 11, name: 'Qalqilya' },
    { id: 2, name: 'Haifa' }
  ],
  refreshTime:60000, //in milli-seconds 60000=1min
C: []
};
