import { CheckNullPipe } from './check-null';
import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewInit, Directive, ElementRef, EventEmitter, NgModule, Output } from '@angular/core';
import { DateTimePipe } from './date-time';
import { DirPipe } from './dir';
import { DisableButtonPipe } from './disable-button';
import { DotsPipe } from './dots';
import { ErrorInputPipe } from './error';
import { ImagePipe } from './image';
import { KeyLangPipe } from './key-lang';
import { LineHtmlPipe } from './line-html.pipe';
import { MaxLengthPipe } from './max-length.pipe';
import { TrustPipe } from './trust';
import { UtilitiesDataPip } from './utilities-data';
import { WithoutPipe } from './without';

@Directive({
    selector: '[ngViewInit]'
})
export class NgInitDirective implements AfterContentInit, AfterViewInit {
    @Output('onViewInit') onViewInit: EventEmitter<any> = new EventEmitter();

    constructor(private element: ElementRef) {

    }

    ngAfterViewInit(): void {

    }

    ngAfterContentInit() {
        setTimeout(() => this.onViewInit.emit(this.element), 10);
    }

}
let pipes = [CheckNullPipe, DirPipe, DateTimePipe, ImagePipe, TrustPipe, DotsPipe, DisableButtonPipe, ErrorInputPipe, UtilitiesDataPip, MaxLengthPipe, KeyLangPipe, LineHtmlPipe, WithoutPipe, NgInitDirective];

@NgModule({
    imports: [CommonModule],
    declarations: pipes,
    exports: pipes
})
export class PipesModule { }
