import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

export const AppRoutes: Routes = [
    {
        path: 'dashboard',
        component: AdminLayoutComponent,

        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/dashboard/changes',
            },
            {
                path: 'changes',
                loadChildren: () => import('./changes/changes.module').then(m => m.ChangesModule)
            },
            {
                path: 'changes/:id',
                loadChildren: () => import('./change/change.module').then(m => m.ChangeModule)
            },
            {
                path: 'companies',
                loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule)
            },
            {
                path: 'companies/:id',
                loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
            },
            {
                path: 'add-company',
                loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
            },
            {
                path: 'users/:id',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule)
            },
            {
                path: 'add-company-user/:company',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule)
            }
        ]
    },
    {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: '',
            loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
        }]
    }
];
