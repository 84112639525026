import { Pipe, PipeTransform } from '@angular/core';
import { UtilitiesService } from './../services/utilities.service';


@Pipe({
    name: 'disableButton',
    pure: false
})
export class DisableButtonPipe implements PipeTransform {

    constructor(private utilites: UtilitiesService) {

    }

    transform(value: any, arg: any = false): any {
        return this.utilites.disabledButtons[value] || arg;
    }

}
