import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';

@Injectable()
export class UsersService {

    constructor(private request: RequestService) {

    }

    login(data: any): Observable<any> {
        return this.request.post('auth/login', data);
    }

    logout(): Observable<any> {
        return this.request.delete('auth/logout');
    }

    getUsers(data: any): Observable<any> {
        return this.request.get('users', data);
    }

    getUser(id: any): Observable<any> {
        return this.request.get('users/' + id);
    }

    createUser(data: any): Observable<any> {
        return this.request.post('users', data);
    }

    signUp(data: any): Observable<any> {
        return this.request.post('auth/signup', data);
    }

    updateUser(data: any): Observable<any> {
        return this.request.post('users/' + data.id, data);
    }

    deleteUser(id: any): Observable<any> {
        return this.request.delete('users/' + id);
    }

    getProfile(): Observable<any> {
        return this.request.get('profile');
    }

    updateProfile(data: any): Observable<any> {
        return this.request.post('profile', data);
    }

    updateCompanyProfile(data: any): Observable<any> {
        return this.request.post('company-profile', data);
    }

    changePassword(data: any): Observable<any> {
        return this.request.put('profile/password', data);
    }

    changePasswordByAdmin(data: any): Observable<any> {
        return this.request.put('users/' + data.id + '/password', data);
    }

}
