import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from './../services/utilities.service';


@Pipe({
    name: 'sharedData',
    pure: false
})
export class UtilitiesDataPip implements PipeTransform {

    public storageUrl = environment.StorageUrl;

    constructor(private utilities: UtilitiesService) {
    }

    transform(value: string): any {
        let self = this;

        let keys = value.split('.');
        let returnData = null;

        keys.forEach(key => {
            if (!returnData) {
                returnData = self.utilities.sharedData[key];
            } else {
                returnData = returnData[key];
            }
        });

        return returnData;
    }

}
