import { Location } from "@angular/common";
import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../environments/environment";

declare var google: any, $: any;

@Injectable()
export class UtilitiesService {
  private eventsHandlers: any = {};
  public disabledButtons: any = {};
  public sharedData: any = {};
  private geocoder: any = null;
  private audios: any = {};
  private user: any = {};
  private accessToken: any = '';

  constructor(
    public santitzer: DomSanitizer,
    public translate: TranslateService,
    public zone: NgZone,
    private location: Location,
    private snackBar: MatSnackBar
  ) {
    this.user = this.getData("user", {});
  }

  disableButton(key: string, value = true) {
    this.disabledButtons[key] = value;
  }

  dataByLang(data: any, key: string) {
    let keyLang = document.dir == "rtl" ? "" : "_en";
    return data && data[key + keyLang]
      ? data[key + keyLang]
      : data && data[key]
        ? data[key]
        : "";
  }

  touchForm(form: any) {
    for (let i in form.controls) {
      form.controls[i].touched = true;
    }
  }

  untouchForm(form: any) {
    for (let i in form.controls) {
      form.controls[i].touched = false;
    }
  }

  setData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  getData(key: string, defaultValue: any = null) {
    let data = defaultValue;

    try {
      let savedData: any;
      savedData = localStorage.getItem(key);

      data = JSON.parse(savedData);
    } catch (error) {
      data = defaultValue;
    }

    return data ? data : defaultValue;
  }

  removeData(key: any) {
    localStorage.removeItem(key);
  }

  clearData() {
    localStorage.clear();
  }

  setLang(lang: any = null) {
    let dir = "ltr";

    if (!lang) {
      lang = this.getData("lang", "ar");
    }

    if (lang == "ar") {
      this.setData("lang", lang);
      dir = "rtl";
    } else {
      this.setData("lang", "en");
      lang = "en";
    }

    document.dir = dir;

    document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.publishEvent("update:lang", lang);
  }

  subscribeEvent(tag: string, handler: any) {
    if (!this.eventsHandlers[tag]) {
      this.eventsHandlers[tag] = [];
    }

    let hasString = false;
    if (handler.toString) {
      hasString = true;
    }

    let found = false;
    for (let i in this.eventsHandlers[tag]) {
      if (
        hasString &&
        this.eventsHandlers[tag][i].toString() == handler.toString()
      ) {
        found = true;
        this.eventsHandlers[tag][i] = handler;
      } else if (
        !hasString &&
        this.isEqual(this.eventsHandlers[tag][i], handler)
      ) {
        found = true;
        this.eventsHandlers[tag][i] = handler;
      }
    }

    if (!found) {
      this.eventsHandlers[tag].push(handler);
    }
  }

  publishEvent(tag: string, data = null) {
    if (this.eventsHandlers[tag] && this.eventsHandlers[tag].length > 0) {
      for (let i in this.eventsHandlers[tag]) {
        this.eventsHandlers[tag][i](data);
      }
    }
  }

  publishEventStartWith(tag: string, data = null) {
    let keys = Object.keys(this.eventsHandlers);
    let searchedFields = [];

    for (let key of keys) {
      if (key.startsWith(tag)) {
        searchedFields.push(key);
      }
    }

    for (let key of searchedFields) {
      if (this.eventsHandlers[key] && this.eventsHandlers[key].length > 0) {
        for (let i in this.eventsHandlers[key]) {
          this.eventsHandlers[key][i](data);
        }
      }
    }
  }

  removeEvent(tag: string, handlerId: number = -1) {
    if (this.eventsHandlers[tag]) {
      if (handlerId >= 0) {
        if (this.eventsHandlers[tag][handlerId]) {
          delete this.eventsHandlers[tag][handlerId];
        }
      } else {
        for (let i in this.eventsHandlers[tag]) {
          delete this.eventsHandlers[tag][i];
        }
        delete this.eventsHandlers[tag];
      }
    }
  }

  // returns ar for arabic language and en otherwise.
  getDeviceLanguage() {
    // get the language from the browser, this can be like "en", "en-US", "fr", "fr-FR", "es-ES"
    let deviceLanguage = window.navigator.language.toLowerCase();

    if (deviceLanguage.startsWith("ar")) {
      deviceLanguage = "ar";
    } else {
      deviceLanguage = "en";
    }

    return deviceLanguage;
  }

  getMapDetails(lat: any, lng: any, cb: any) {
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }

    this.zone.run(() => {
      this.geocoder.geocode(
        {
          latLng: { lat: lat, lng: lng },
        },
        (responses: any) => {
          this.zone.run(() => {
            if (responses && responses.length > 0) {
              let address = (responses[0].formatted_address + "").replace(
                "، فلسطين",
                ""
              );
              let response = responses[0];

              cb(address, response, responses);
            } else {
              cb(null, null, null);
            }
          });
        }
      );
    });
  }

  getMapLatLng(address: any, cb: any) {
    if (!this.geocoder) {
      this.geocoder = new google.maps.Geocoder();
    }

    this.zone.run(() => {
      this.geocoder.geocode(
        {
          address: address,
        },
        (responses: any) => {
          this.zone.run(() => {
            if (responses && responses.length > 0) {
              let response = responses[0];
              cb(
                {
                  lat: response.geometry.location.lat,
                  lng: response.geometry.location.lng,
                },
                response,
                responses
              );
            } else {
              cb(null, null, null);
            }
          });
        }
      );
    });
  }

  setTitle(name: any) {
    if (name) {
      document.title =
        this.translate.instant(name);
    }
  }

  async sendNotification(text: any,
    params: any = {},
    cssClass: any = "success",
    duration: any = null,
    position: any = { h: 'center', v: 'top' }
  ) {
    let self = this;

    let translatedText = "";

    if (params) {
      let keys = Object.keys(params);

      for (let k of keys) {
        params[k] = self.translate.instant(params[k]);
      }

      translatedText = self.translate.instant(text, params);
    } else {
      translatedText = self.translate.instant(text);
    }


    this.snackBar.open(translatedText, "", {
      duration: duration ? duration : cssClass == "danger" ? 4000 : 3000,
      panelClass: cssClass,
      horizontalPosition: position?.h ? position.h : 'center',
      verticalPosition: position?.v ? position.v : 'top',
    });

  }

  goBack() {
    this.location.back();
  }

  securityUrl(url: any, type = "url") {
    if (type == "url") {
      return this.santitzer.bypassSecurityTrustUrl(url);
    }

    if (type == "style") {
      return this.santitzer.bypassSecurityTrustStyle(url);
    }

    if (type == "resource") {
      return this.santitzer.bypassSecurityTrustResourceUrl(url);
    }

    return url;
  }

  clone(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  isEqual(obj1: any, obj2: any) {
    obj1 = JSON.stringify(obj1);
    obj2 = JSON.stringify(obj2);
    if (obj1 == obj2) {
      return true;
    } else {
      return false;
    }
  }

  getFile(
    opt: any = {
      types: ["png", "jpg", "jpeg"],
      size: 4,
      accept: null,
      multiple: false,
    }
  ): Promise<any> {
    if (!opt) {
      opt = { types: ["png", "jpg", "jpeg"], size: 8 };
    }
    if (!opt.size) {
      opt.size = 8;
    }
    if (!opt.types) {
      opt.types = ["png", "jpg", "jpeg"];
    }

    let self = this;
    return new Promise((resolve, error) => {
      this.zone.run(() => {

        let fileElement: any = document.createElement("input");
        fileElement.setAttribute("type", "file");
        fileElement.setAttribute("hidden", "true");

        if (opt.accept) {
          fileElement.setAttribute("accept", opt.accept);
        }

        if (opt.multiple) {
          fileElement.setAttribute("multiple", "true");
        }

        fileElement.style.display = "none";
        fileElement.id = "fileUploadUtilites";
        $(document.body).append(fileElement);

        $(fileElement).on("cancel", (event: any) => {
          fileElement = null;
          $(fileElement).remove();
          // error({ error: 4, type: 'error_no_file', event });
        });

        fileElement.onchange = (event: any) => {
          self.zone.run(() => {
            fileElement = null;
            let file: File;
            let files = [];

            if (event.target) {
              files = event.target.files;
              console.log(files);
            } else if (event.path) {
              files = event.path;
            }

            let validFiles: any = [];

            let onFinish = () => {
              if (validFiles.length == files.length) {
                resolve(opt.multiple ? validFiles : validFiles[0]);
              }
            };

            for (let file of files) {
              if (file != null) {
                // check size
                let size = file.size / 1024.0 / 1024.0;
                if (size > opt.size) {
                  self.sendNotification("ERROR_FILE_SIZE", { size: opt.size },
                    "danger",
                    4000
                  );
                  error({ error: 1, type: "error_size", event });
                }

                let sameType = false;

                opt.types.forEach((type: any) => {
                  if (file.type.indexOf(type) >= 0) {
                    sameType = true;
                  }
                });

                if (!sameType) {
                  let name = (file.name + "").split(".");
                  let ext = name[name.length - 1].toLowerCase();
                  opt.types.forEach((type: any) => {
                    if (ext == type) {
                      sameType = true;
                    }
                  });

                  if (!sameType) {
                    self.sendNotification('ERROR_FILE_TYPE',
                      { types: opt.types.join(", ") },
                      "danger",
                      4000
                    );
                    error({ error: 2, type: "error_type", event });
                  }
                }

                let fileE = document.getElementById("fileUploadUtilites");
                if (fileE) {
                  fileE.remove();
                }

                if ((file.type + "").indexOf("image") >= 0) {
                  var reader = new FileReader();
                  reader.onload = function (readerEvent: any) {
                    var img: any = new Image();
                    img.onload = function (imageEvent: any) {
                      // Resize the image
                      var canvas: any = document.createElement("canvas"),
                        max_size = 1024, // TODO : pull max size from a site config
                        width = img.width,
                        height = img.height;

                      if (width > height) {
                        if (width > max_size) {
                          height *= max_size / width;
                          width = max_size;
                        }
                      } else {
                        if (height > max_size) {
                          width *= max_size / height;
                          height = max_size;
                        }
                      }

                      canvas.width = width;
                      canvas.height = height;

                      canvas
                        .getContext("2d")
                        .drawImage(img, 0, 0, width, height);
                      var dataUrl = canvas.toDataURL(file.type);
                      var resizedImage = self.dataURLToBlob(dataUrl, file.name);
                      validFiles.push({
                        file: resizedImage,
                        path: (
                          window.URL || (window as any).webkitURL
                        ).createObjectURL(resizedImage),
                      });

                      onFinish();
                    };
                    img.src = readerEvent.target.result;
                  };

                  reader.readAsDataURL(file);
                } else {

                  validFiles.push({
                    file: file,
                    path: (
                      window.URL || (window as any).webkitURL
                    ).createObjectURL(file),
                  });
                  onFinish();
                }

              } else {
                let fileE = document.getElementById("fileUploadUtilites");
                if (fileE) {
                  fileE.remove();
                }
                error({ error: 3, type: "error_not_support", event });
              }
            }

            if (opt.multiple) {
              onFinish();
            } else {
              if (validFiles.length > 0) {
                onFinish();
              }
            }
          });
        };

        fileElement.click();
      });
    });
  }

  dataURLToBlob(dataURL: any, name: any) {
    var BASE64_MARKER = ";base64,";
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(",");
      var contentType = parts[0].split(":")[1];
      var raw = parts[1];

      return new Blob([raw], { type: contentType });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(":")[1];
    var raw: any = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    var file: any = new Blob([uInt8Array], { type: contentType });
    file.name = name;
    return file;
  }

  scrollTop(animation = true) {
    if (animation) {
      $("html, body").animate({ scrollTop: 0 }, "slow");
    } else {
      window.scroll({
        top: 0,
        left: 0,
      });
    }
  }

  animateScrollTo(target: any, scrolledElement: any = "html, body") {
    $(scrolledElement).animate({
      scrollTop: $(target).getBoundingClientRect().top
    });
  }

  scrollTo(el: HTMLElement, animation = true) {
    let top = el.getBoundingClientRect().top;

    if (animation) {
      $("html, body").animate({ scrollTop: top }, "slow");
    } else {
      window.scroll({
        top: top,
        left: 0,
      });
    }
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  addCountry(countries: Array<any>) {
    countries.unshift({
      id: null,
      name: "Select Country",
      name_ar: "اختر الدولة",
    });
  }

  addCity(cities: Array<any>) {
    cities.unshift({ id: null, name: "Select City", name_ar: "اختر المدينة" });
  }

  deleteNull(array: Array<any>) {
    let index = array.findIndex((value, i, obj) => {
      return value.id == null;
    });

    if (index >= 0) {
      array.splice(index, 1);
    }
  }

  trans(key: any, args: any = null) {
    return this.translate.instant(key, args);
  }

  inArray(item: any, array: any, isNumber = true) {
    let index = array.indexOf(item);
    if (index >= 0) {
      return true;
    }

    for (let i of array) {
      if (isNumber) {
        i = parseInt(i);
      }
      if (i == item) {
        return true;
      }
    }

    return false;
  }

  convertNumbers(text: any) {
    text = text.replace(/١/g, "1");
    text = text.replace(/٢/g, "2");
    text = text.replace(/٣/g, "3");
    text = text.replace(/٤/g, "4");
    text = text.replace(/٥/g, "5");
    text = text.replace(/٦/g, "6");
    text = text.replace(/٧/g, "7");
    text = text.replace(/٨/g, "8");
    text = text.replace(/٩/g, "9");
    text = text.replace(/٠/g, "0");
    text = text.replace(/[^\d.-^\+]/g, '');

    return text;
  }

  itemsInArray(items: any, array: any, isNumber = true) {
    let inArray = true;

    for (let a of items) {
      if (isNumber) {
        a = parseInt(a);
      }
      if (!this.inArray(a, array)) {
        inArray = false;
      }
    }

    return inArray;
  }

}
