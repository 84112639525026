import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'error',
    pure: false
})
export class ErrorInputPipe implements PipeTransform {

    constructor() {

    }

    transform(value: any): any {

        if (value.invalid && (value.dirty || value.touched)) {
            return true;
        } else {
            return false;
        }
    }

}
