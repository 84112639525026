import { UtilitiesService } from './../services/utilities.service';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';


@Pipe({
    name: 'image',
    pure: false
})
export class ImagePipe implements PipeTransform {

    public storageUrl = environment.StorageUrl;

    constructor(private utilities: UtilitiesService) {
    }

    transform(value: any, thump = false, image = 'image'): any {

        if (value) {
            if ((value + '').indexOf('blob') >= 0) {
                return this.utilities.securityUrl(value);

            } else if ((value + '').indexOf('http') >= 0 || (value + '').indexOf('ionic:') >= 0 || (value + '').indexOf('data:image') >= 0) {
                return value;

            } else {
                return this.storageUrl + (thump ? 'thump/' : '') + value;
            }
        } else {
            return '/assets/img/default-' + image + '.png';
        }
    }

}
