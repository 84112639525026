import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestService } from './request.service';

@Injectable()
export class CompaniesService {

    constructor(private request: RequestService) {

    }

    getCompaniesList(data: any): Observable<any> {
        return this.request.get('', data);
    }

    getCompaniesPublic(data: any): Observable<any> {
        return this.request.get('companiespublic', data);
    }

    getCompanies(data: any): Observable<any> {
        return this.request.get('companies', data);
    }

    getChanges(data: any): Observable<any> {
        return this.request.get('changes', data);
    }

    getChange(id: any): Observable<any> {
        return this.request.get('changes/' + id);
    }

    changeAction(data: any): Observable<any> {
        return this.request.put('changes/' + data.id, data);
    }

    getCompany(id: any): Observable<any> {
        return this.request.get('companies/' + id);
    }

    createCompany(data: any): Observable<any> {
        return this.request.post('companies', data);
    }

    updateCompany(data: any): Observable<any> {
        console.log(data)
        return this.request.post('companies/' + data.id, data);
    }

    deleteCompany(id: any): Observable<any> {
        return this.request.delete('companies/' + id);
    }

    getCompanyProfile(): Observable<any> {
        return this.request.get('company-profile');
    }

    updateCompanyProfile(data: any): Observable<any> {
        return this.request.post('company-profile', data);
    }

}
