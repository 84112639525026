import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLength'
})
export class MaxLengthPipe implements PipeTransform {

  transform(value: any, disabled = false): any {
    if (value) {
      return disabled ? value : (value + '').slice(0, 150) + '...';
    } else {
      return '';

    }
  }

}
